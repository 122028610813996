import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import HttpsRedirect from 'react-https-redirect';

import Page from '../../src/components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="shortcut icon"
          href="https://d3o2xo3oi386wy.cloudfront.net/images/favicons/favicon.ico"
        />
        <meta name="theme-color" content="#fec201" />
        <meta
          name="description"
          content="With nearly 20 years of experience and repeated success building high-velocity teams, Ori Damary has an excellent track record guiding business strategy with established and emerging technologies. He is an ambassador for simplicity and transparency, and aims to curate authentic, collaborative, and inspired team-environments."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://d3o2xo3oi386wy.cloudfront.net/images/ori-believe-cropped.jpg"
        />
        <meta
          property="og:title"
          content="Ori Damary: Engineering Leader & Startup Advisor"
        />
        <meta
          property="og:description"
          content="With nearly 20 years of experience and repeated success building high-velocity teams, Ori Damary has an excellent track record guiding business strategy with established and emerging technologies. He is an ambassador for simplicity and transparency, and aims to curate authentic, collaborative, and inspired team-environments."
        />
        <meta
          property="og:url"
          content="https://www.oridamary.com"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Permanent+Marker:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Overpass&display=swap"
          rel="stylesheet"
        />
        <title>Ori Damary: Engineering Leader & Startup Advisor</title>
      </Helmet>
      <HttpsRedirect>
        <Page>
          {props.children}
        </Page>
      </HttpsRedirect>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

/*
primary - used to represent primary interface elements for a user. 
It's the color displayed most frequently across your app's screens and components.

secondary - used to represent secondary interface elements for a user. 
It provides more ways to accent and distinguish your product. Having it is optional.

error - used to represent interface elements that the user should be made aware of.

warning - used to represent potentially dangerous actions or important messages.

info - used to present information to the user that is neutral and not necessarily important.

success - used to indicate the successful completion of an action that user triggered.

*/

export const light = {
  color1: {
    main: `#fec201`,
    shade900: '#ff7100',
    shade800: '#ff9100',
    shade700: '#fea200',
    shade600: '#feb500',
    shade500: '#fec201',
    shade400: '#fecc26',
    shade300: '#fed74f',
    shade200: '#fee182',
    shade100: '#feedb3',
    shade0: '#fff8e1',
  },
  color2: {
    main: `#02059f`,
    shade900: '#02059f',
    shade800: '#3015aa',
    shade700: '#401bb1',
    shade600: '#5024ba',
    shade500: '#5a28bf',
    shade400: '#754ac9',
    shade300: '#8e6ad3',
    shade200: '#af96df',
    shade100: '#cfc0eb',
    shade0: '#ece5f7',
  },
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .4)',
  mode: 'light',
  primary: {
    main: '#02059F',
    light: '#FFF',
    dark: '#02059F',
    contrastText: '#fec201',
  },
  secondary: {
    light: '#ffb74d',
    main: '#fec201',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#000',
    secondary: '#333',
    dark: '#02059F',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fec201',
    default: '#FFF',
    level2: '##fecc26',
    level1: '##fed74f',
  },
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#1976d2',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};
